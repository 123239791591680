import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Super TipCalc - Super Easy Tip Calculator!<br/>
	  <br/> 
          New Web Site Soon!<br/>
        </p>
      </header>
    </div>
  );
}

export default App;
